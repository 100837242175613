import React, {Component} from "react"
import Sprites from "../components/sprites"
import BgVideo from "../components/background-video"
// import Brands from "../components/brands"
import Clients from "../components/clients"
import Awards from "../components/awards"
import Buy from "../components/buy"
import Footer from "../components/footer"
import { Link } from "gatsby"
import SEO from "../components/seo"

import Video1 from "../video/melbourne-brewery.mp4"
import Video2 from "../video/melbourne-distilling.mp4"

import MailChimpForm from '../components/mailchimpform'

import logoGold from "../img/gh-rough.jpg"
import haccp from "../img/haccp.png"
import sedex from "../img/sedex.png"
import gh1 from "../img/gh-1.jpg"
import gh2 from "../img/gh-2.jpg"
import gh3 from "../img/gh-4.jpg"
import gh4 from "../img/gh-5.jpg"
import gh5 from "../img/gh-6.jpg"


class Home extends Component {
  

  render() {
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }
  

  return (
    <div>
       <script
        dangerouslySetInnerHTML={{
          __html: `
                  window.addEventListener('load',function(){
                  if(document.getElementsByClassName('get-started-form--submit').length>0){
                  document.getElementsByClassName('get-started-form--submit')[0].addEventListener('click',function(){
                    var myVar = setInterval(function(){ var x = 0;  if(x==0){
                        if(document.getElementsByClassName('thank-you').length>0){
                        gtag('event', 'conversion', {'send_to': 'AW-630826683/T_ARCNKS3f0CELvN5qwC'});
                        clearInterval(myVar); x=1;
                        }  }  }, 1000);
                  })
                  }
                  })
              `,
        }}
      />
      <SEO 
        title="Contract Distilling, Brewing, Canning &amp; Bottling | Australia" description="Gypsy Hub are contract distilling, brewing, canning &amp; bottling providers in Melbourne offering solutions to Australian commercial craft beverage producers"   />
      <Sprites></Sprites>
        <section className="title-screen background--dark" id="title-screen">
          <BgVideo></BgVideo>
        </section>
        <section className="about background--dark" name="about" id="about">
          <div className="wrapper">
            <div className="row">
              <div className="col-12">
                <h1>We're an award-winning contract beverage manufacturer, having worked with many established brands, as well as start-ups over many years in Australia.</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <p>Gypsy Hub can offer a full end-to-end service, from recipe and development, right through to distilling, brewing, canning, bottling or kegging your commercially ready product. We also offer bonded warehousing facilities and operate out of a state-of-the-art, HACCP &amp; Sedex certified facility in Melbourne's inner north.</p>
                <img className="logo-small" src={logoGold} alt="gypsy hub gold logo" width="150" height="150" />
              </div>
            </div>
          </div>
        </section>
       
        <section className="services background--mid">
          <div className="wrapper">
            {/* <div className="row">

              <div className="col-12">
                <div className="video--content video--bubbles">
                <video loop autoPlay muted playsInline className="video__video video--full">
                    <source src={Video1} type="video/mp4" />
                </video>
                </div>
              </div>
            </div> */}
            <div className="row row--services" name="services" id="services" data-sal-duration="100">
              <div className="col-12">
                <h2 className="h1 no-top">Our Production Capabilities</h2>
                </div>
            </div>
            <div className="row row--services">
              <div className="col-6">
              <a target="_blank" rel="noopener noreferrer" href="https://www.treadsoftlydrinks.com/">
                <img className="fade-up" data-sal="slide-up" data-sal-duration="600" data-sal-delay="100" data-sal-easing="ease" src={gh1} alt="gypsy hub contract production" width="576" height="564" /> 
              </a>    
            </div>
              <div className="col-6">
              <p className="no-top">Our highly experienced distilling, brewing, production, warehouse, business development and support teams, will work closely with you to offer the best contract beverage and co-packing services in Australia.</p>
              <ul>
                <li>Planning &amp; logistics</li>
                <li>Recipe formulation</li>
                <li>R&amp;D</li>
                <li>Commercial Distilling or Brewing</li>
                <li>Contract Packing - Cans, bottles, Bag-in-box plus more</li>
                <li>Full contract distilling &amp; brewing</li>
                <li>Gypsy brewing & distilling</li>
              </ul>
              <h2>Recipe &amp; Development</h2>
              <ul className="clr-bt">
                <li>Beer</li>
                <li>Gin</li>
                <li>Vodka</li>
                <li>Whisky</li>
                <li>Grappa</li>
                <li>Rum</li>
                <li>Liqueurs</li>
                <li>Cocktails</li>
                <li>RTD's</li>
                <li>Soft drinks</li>
              </ul>
            </div>
            </div>
            <div className="row row--services">
              <div className="col-12">
                <hr className="row--services__hr" />
              </div>
            </div>
            <div className="row row--services">
            <div className="col-6">
            <h2>Distilling</h2>
              <ul>
                <li>Ability to distil gin, vodka, grappa, whisky &amp; rum with both column &amp; pot still capabilities</li>
                <li>Macerations &amp; barrel ageing of distilled spirits</li>
              </ul>
              </div>
              <div className="col-6">
                <img className="fade-up" data-sal="slide-up" data-sal-duration="600" data-sal-delay="100" data-sal-easing="ease" src={gh2} alt="gypsy hub distilling" width="576" height="324" />
              </div>
              </div>
              <div className="row row--services">
              <div className="col-12">
                <hr className="row--services__hr" />
              </div>
            </div>
            <div className="row row--services">
            <div className="col-6">
                <img className="fade-up" data-sal="slide-up" data-sal-duration="600" data-sal-delay="100" data-sal-easing="ease" src={gh5} alt="gypsy hub brewing supplies" width="576" height="324" />
              </div>
              <div className="col-6">
              <h2>Brewing</h2>
              <ul className="clr-bt">
                <li>4 vessel 40HL brew house capable of batch sizes (40HL, 75HL and 200HL)</li>
                <li>Wash brewing</li>
                <li>Barrel-aged beer</li>
              </ul>
              </div>
              </div>
              <div className="row row--services">
              <div className="col-12">
                <hr className="row--services__hr" />
              </div>
            </div>
            <div className="row row--services">
              <div className="col-6">
              <h2>Packaging</h2>
              <ul>
                <li>3000 CPH canning (slim, sleek, and classic)</li>
                <li>2500 BPH spirit bottling, T-cap corking, shrink sealing and wax dipped</li>
                <li>5000 BPH wine bottling, corking, crowning, twist cap closure and labelling</li>
                <li>4000 BPH beer, cider and RTD filling with crown capping and ROPP</li>
                <li>Miniature bottling 50ml and 100ml</li>
                <li>Bag in a box - 1L, 2L, 3L, 5L and 10L both optiserve and flex tap style</li>
                <li>Kegging batches in 20L, 30L and 50L kegs</li>
              </ul>
              <h2>Bulk Liquids</h2>
              <p>Gypsy Hub can supply and package or supply bulk liquids including:</p>
              <ul>
                <li>Rum</li>
                <li>Gin</li>
                <li>Vodka</li>
                <li>Whisky</li>
                <li>Mezcal</li>
              </ul>
              <h2>Minimum Order Quantity</h2>
              <ul className="clr-bt">
                <li>Beer - 4,000L</li>
                <li>Spirits - 1,000L</li>
                <li>Wine (Bottling) - 10,000L</li>
                <li>RTD/Soda - 4,000L</li>
              </ul>
            </div>
            <div className="col-6">
              <img className="fade-up" data-sal="slide-up" data-sal-duration="600" data-sal-delay="100" data-sal-easing="ease" src={gh4} alt="gypsy hub packaging solutions" width="576" height="564"  />
            </div>
           
            </div>
            <div className="row row--services">
              <div className="col-12">
                <hr className="row--services__hr" />
              </div>
            </div>
            <div className="row row--services">
             
            <div className="col-6">
               <img className="fade-up" data-sal="slide-up" data-sal-duration="600" data-sal-delay="100" data-sal-easing="ease" src={gh3} alt="gypsy hub storage solutions" width="576" height="564"  />
              </div>
              <div className="col-6">
                <h2>Storage</h2>
                <ul>
                  <li>Bonded storage</li>
                  <li>Ability to import liquid under bond</li>
                  <li>Ability to load shipping containers</li>
                  <li>Pack and unpack</li>
                </ul>
                <h2>Filtration and stabilisation</h2>
                <ul>
                  <li>Sterile filtration through lenticular and membrane filtration</li>
                  <li>Crossflow filtration</li>
                  <li>Flash pasteurisation</li>
                  <li>Carbon filtering</li>
                  <li>Clarification filtering</li>
                </ul>
                <h2>Process made easy</h2>
                <ul className="clr-bt">
                  <li>Gypsy Hub is a one-stop shop for the sourcing and supply of all your dry goods or provide the flexibility to work with the customer through their own supply channels</li>
                </ul>   
              </div>
              </div>
              
          </div>
        </section>
        
        <section className="equipment background--dark">
          <div className="wrapper">
           <h2 className="h1">Gypsy hub is one of the very few contract manufacturing businesses in Australia to provide all our services under one roof in our state-of-the-art HACCP certified facility in Melbourne's inner north</h2>
           <div className="row">
            <div className="col-8">
               <p className="large-text">Our facility is equipped with a distillery, brewery, canning and bottling lines, labelling systems, a pasteuriser, tank farms, an oak room, and bonded warehouse infrastructure with integrated, local or international logistics capabilities. Our operations are supported by a highly skilled team, including qualified distillers, brewers, QA officers, engineers, logistics specialists & administrative personnel. Working collaboratively within a singular facility, we ensure a seamless and efficient process, from R&amp;D through to the completion of a commercially-ready beverage, prepared for distribution and retail.</p>
              <img className="logo-small" src={logoGold} alt="gypsy hub gold logo" width="150" height="150" />
            </div>
           </div>
          </div>
        </section>
        <section className="fullscreen-video">
          <div className="background-video">
          <div className="video--content video--bubbles">
          <video loop autoPlay muted playsInline className="video__video video--full">
              <source src={Video1} type="video/mp4" />
          </video>
          </div>
          </div>
        </section>
        
        <section className="brands background--light" id="brands" name="brands" data-sal-duration="100">
        <div className="wrapper wrapper--brands">
        <div className="row">
              <div className="col-12">
                <h2 className="h1">Past Contract Partners</h2>
              </div>
            </div>
            {/* <Brands></Brands> */}
            {/* <div className="wrapper--inner wrapper--clients"> */}
             <Clients></Clients>
            {/* </div> */}
          </div>
          </section>
          <section className="brands background--mid">
            <div className="wrapper wrapper--awards">
            {/* <div className="wrapper--inner wrapper--clients"> */}
            <div className="row">
              <div className="col-12">
                <h2 className="h1">Industry Recognition</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <p>Over the past decade, the Gypsy Hub team have become the most awarded producer of craft brands in Australia. Our collaborative approach, attention to detail and industry-leading expertise has resulted in our client’s brands receiving more than 100 medals both nationally and internationally, including Gypsy Hub being named Best Independent Bottler by Tasting Australia.</p>
              </div>
            </div>
             <Awards></Awards>
            {/* </div> */}
         </div>
        </section>
        
        <section className="get-started background--mid">
          <div className="wrapper">
          {/* <div className="row">
              <div className="col-12">
                <div className="video--content video--fluid">
          <video loop autoPlay muted playsInline className="video__video video--full">
                <source src={Video2} type="video/mp4" />
            </video>
            </div>
            </div>
            </div> */}
            <div className="wrapper--inner" name="get-started" id="get-started" data-sal-duration="100">
              <div className="row"><div className="col-12">
                <h2>Get started:</h2>
                <MailChimpForm></MailChimpForm>
              </div>
            </div>
            </div>
            <div className="wrapper--inner wrapper--buy">
            <Buy></Buy>
            </div>
          </div>
        </section>
        <section className="fullscreen-video">
        <div className="background-video">
        <div className="video--content video--fluid">
            <video loop autoPlay muted playsInline className="video__video video--full">
                  <source src={Video2} type="video/mp4" />
              </video>
              </div>
          </div>
        </section>
        <section className="footer background--dark" name="contact" id="contact" data-sal-duration="100">
         <Footer></Footer>
          <div className="container footer--bottom">
            <div className="wrapper--outer">
              <img className="logo-small" src={logoGold} alt="gypsy hub gold logo" width="150" height="150" />
              <img className="logo-small logo-haccp" src={haccp} alt="haccp logo" width="150" height="150" />
              <img className="logo-small logo-haccp" src={sedex} alt="sedex logo" width="150" height="150" />
              <Link className="up-arrow" to="/#title-screen">→</Link>
            </div>
          </div>
        </section>
    </div>
  );
  }
}
export default Home

// }
