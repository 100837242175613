import React from "react"
import fb from "../img/fbpropak.png"
import vitis from "../img/vitis-winemakers.png"
import hmi from "../img/hmi.png"

export default function Buy() {
    return (
            <div>

                <h2>Want to buy distilling, brewing, winemaking or beverage equipment?</h2>
              <p>Please get in touch with one of our preferred local specialist suppliers listed below:</p>

            <div className="client-logo-wrapper">
                <a className="client-logo client-logo--artillery" href="https://www.fbpropak.com/" target="_blank" rel="noreferrer"><img src={fb} alt="FBPropak" /></a>
                <a className="client-logo" href="https://www.vitiswinemakers.com/" target="_blank" rel="noreferrer"><img src={vitis} alt="Vitis" /></a>
                <a href="https://www.homemakeit.com.au" rel="noreferrer" target="_blank" ><img className="logo-small logo-hmi" src={hmi} alt="home make it melbourne logo" width="150" height="150" /></a>
            </div>
        </div>

    );
}
